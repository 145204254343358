import React, { useEffect, useMemo, useState } from "react";
import { Reacttable } from "../component/Reacttable";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { useMyGlobal } from "../component/Global";
import { Button, Form, FormInput, Icon, Input, Label, Popup } from "semantic-ui-react";
import { Toaster } from "../component/Toaster";
import { Modal } from "rsuite";

export const MasterArticle = () => {
    const [dataArticle, setDataArticle] = useState([])
    const { IDR, host } = useMyGlobal()
    const [ excelFile, setExcelFile ] = useState(null)
    const [loadingImpt, setLoadingImpt] = useState(false)
    const [totalItem, setTotalItem] = useState(0)
    const [totalArt, setTotalArt] = useState(0)
    const [totalBrand, setTotalBrand] = useState(0)
    const urlTemplate = `${host}/import_template/master_article.xlsx`
    const [noData, setNoData] = useState(false)
    const [modalEditBrcd, setModalEditBrcd] = useState(false)
    const [modalEditSeason, setModalEditSeason] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const getData = async () => {
        await axios({
            method: 'GET',
            url: `${host}/product/index.php?action=productMaster`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        })
            .then(res => { 
                if (res.data.data.length === 0){
                    setNoData(true)
                }
                if (res.data.status === 200){
                    const dataLength = res.data.data.length 
                    const artLength = new Set(res.data.data.map(product => product.article)).size
                    const brandLength = new Set(res.data.data.map(product => product.brand)).size
                    setDataArticle(res.data.data)
                    setTotalItem(dataLength)
                    setTotalArt(artLength)
                    setTotalBrand(brandLength)
                }
            })
    }

    const handleImport = async (event) => {
        setLoadingImpt(true)
        const formData = new FormData();
        formData.append('excelFile', excelFile)
        formData.append('action', 'importProduct' )
     
        await axios({
            method: "POST",
            url: `${host}/product/index.php?action=importProduct`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData
        }).then(res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Import Success',
                    position:'top-right'
                })
                getData()
            }
            setLoadingImpt(false)
            setExcelFile(null)
        }).catch(error => {
            Toaster.fire({
                icon:'error',
                text: error,
                position:'top-right'
            })
            setLoadingImpt(false)
          });
    };

    const handleFile = (event) => {
        const file = event.target.files[0];
        setExcelFile(file);
    }

    const columns = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Barcode",
            accessor: "barcode",
            Cell:({cell}) => (
                <div>
                    <Popup content='Edit this barcode' size="tiny" trigger={
                    <span><Icon name="edit" color="blue" style={{cursor:'pointer'}} onClick={() => handleClickEditBarcode(cell.row.original)}/></span>
                    }/>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Desc",
            accessor: "description",
            Cell:({cell}) => (
                <div style={{width:'180px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (
                IDR.format(cell.value)
            )
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                IDR.format(cell.value)
            )
        },
        {
            Header: "Season",
            accessor: "season",
            Cell:({cell}) => (
                <div>
                    <Popup content='Edit this seasson' size="tiny" trigger={
                    <span><Icon name="edit" color="blue" style={{cursor:'pointer'}} onClick={() => handleClickEditSeasson(cell.row.original)}/></span>
                    }/>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Brand Group",
            accessor: "brand_group"
        },
        {
            Header: "Model",
            accessor: "model"
        },
        {
            Header: "Trend",
            accessor: "trend"
        },
    ])

    const handleClickEditBarcode = (data) => {
        setSelectedRow(data)
        setModalEditBrcd(true)
    }

    const handleClickEditSeasson = (data) => {
        setSelectedRow(data)
        setModalEditSeason(true)
    }

    const handleSubmitEditBarcode = async () => {
        await axios({
            method: 'POST',
            url: `${host}/product/index.php?action=editBarcode`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:{
                article:selectedRow.article,
                size:selectedRow.size,
                barcode:selectedRow.barcode,
            }
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success Edit Barcode',
                    position:'top-right'
                })
                setModalEditBrcd(false)
                getData()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const handleSubmitEditSeason = async () => {
        await axios({
            method: 'POST',
            url: `${host}/product/index.php?action=editSeason`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:{
                article:selectedRow.article,
                season:selectedRow.season,
            }
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success edit seasson',
                    position:'top-right'
                })
                setModalEditSeason(false)
                getData()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Article</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Form onSubmit={handleImport}>
                            <Form.Group inline>
                                <Form.Input type="file" onChange={handleFile} label="Excel File"/>
                                <Button type="submit" content="Import" icon="share square outline" loading={loadingImpt} color="blue"/>
                                <span className="ms-3 me-1">Download template</span><span><a href={urlTemplate}>click here</a></span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Reacttable data={dataArticle} columns={columns} />
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Popup content={'Total ' + totalItem + ' Item`s'} trigger={
                            <Label content={totalItem + " Item's"} icon="box" color="blue"/>
                        }/>
                        <Popup content={'Total ' + totalArt + ' Article`s'} trigger={
                            <Label content={totalArt + ' Article'} icon="sticky note" color="blue"/>
                        }/>
                        <Popup content={'Total ' + totalBrand + ' Brand`s'} trigger={
                            <Label content={totalBrand + ' Brand'} icon="registered" color="blue"/>
                        }/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalEditBrcd} onClose={() => setModalEditBrcd(false)} backdrop='static' overflow={false} size='xs'>
                <Modal.Header>
                    <Modal.Title>Edit Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditBarcode" onSubmit={handleSubmitEditBarcode}>
                        <FormInput type="text" value={selectedRow?.barcode || ""} onChange={(e) => setSelectedRow(prev => ({ ...prev, barcode: e.target.value }))} label="Barcode to edit"/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Barcode" type="submit" form="frmEditBarcode" color="blue" icon="save"/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalEditSeason} onClose={() => setModalEditSeason(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Seasson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditSea" onSubmit={handleSubmitEditSeason}>
                        <FormInput type="text" value={selectedRow?.season} onChange={(e) => setSelectedRow(prev => ({ ...prev, season: e.target.value }))}/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Seasson" type="submit" form="frmEditSea" color="blue" icon="save"/>
                </Modal.Footer>
            </Modal>
        </>
    )
}